<!--  -->
<template>
  <div class="erg_imgba">
    <img src="../../image/yingdao_APPxiazaiye@2x.png" alt="" />
    <div class="xiding-r">
      <div class="erjgijh_jijegh" v-if="isShow">{{ code }}</div>
      <div
        class="fuzhifiwf"
        id="tag-copy"
        :data-clipboard-text="code"
        @click="onCopy($event, code)"
         v-if="isShow"
      >
        复制此链接
      </div>
      <div class="ijiiqw_jlad">{{isShow==true?'请将复制的连接用浏览器打开':'暂无IOS,敬请期待'}}</div>
      <img class="eige_img" src="../../image/logo_image.png" alt="" />
      <img class="er_juie" src="../../image/logo_ziti@2x.png" alt="">
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      code: "http://cheshiji.com.cn/active/lineApp",
      isShow:false
    };
  },
  created() {},
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //复制
    onCopy() {
      const clipboard = new Clipboard("#tag-copy");
      clipboard.on("success", (e) => {
        this.$message({ type: "success", message: "复制成功,打开浏览器粘贴" });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message({ type: "waning", message: "该浏览器不支持自动复制" });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    var u = navigator.userAgent,
      app = navigator.appVersion;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      this.isShow=true
    }
    if (isIOS) {
      this.isShow=false
    }
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
*{
  margin: 0;
  padding: 0;
}
.erg_imgba {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.erg_imgba > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.xiding-r {
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 35px;
}
.erjgijh_jijegh {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 14px;
  color: #ffffff;
}
.fuzhifiwf {
  width: 82px;
  height: 23px;
  background: #ffffff;
  opacity: 1;
  border-radius: 12px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #ff6600;
  margin-top: 16px;
}
.ijiiqw_jlad {
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 17px;
  color: #fdba7d;
  margin-top: 10px;
}
.eige_img {
  width: 62px;
  height: 62px;
  margin-top: 36px;
}
.er_juie{
  width:72.97px;
  height:21.53px;
  margin-top: 10px;
}
</style>